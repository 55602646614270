import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores/userStore';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/Home.vue'),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/factions',
		name: 'Factions',
		component: () => import('../views/Factions.vue'),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/factions/:factionid',
		name: 'Faction',
		component: () => import('../views/Faction.vue'),
		meta: {
			authRequired: true
		},
		props: true
	},
	{
		path: '/factions/killteams/:killteamid',
		name: 'KillTeam',
		component: () => import('../views/KillTeam.vue'),
		meta: {
			authRequired: true
		},
		props: true
	},
	{
		path: '/factions/killteams/fireteams/:fireteamid',
		name: 'FireTeam',
		component: () => import('../views/FireTeam.vue'),
		meta: {
			authRequired: true
		},
		props: true
	},
	{
		path: '/killteams/:id',
		name: 'MyKillTeamDetails',
		component: () => import('../components/KillTeams/Details.vue'),
		props: (route) => ({ id: route.params.id }),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/units/:id',
		name: 'UnitDetails',
		component: () => import('../components/KillTeams/Unit.vue'),
		props: (route) => ({
			unitId: route.params.id,
			unitProp: route.params.unit
		}),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/Login.vue')
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'not-found',
		component: () => import('../components/utils/NotFound.vue')
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach(async (to, from, next) => {
	const store = useUserStore();
	if (!store.userData)
		store.fetchUser();

	let authRequired = to.matched.some((record) => record.meta.authRequired);

	if (authRequired && !store.userData.loggedIn) {
		next('/login');
	} else if (!authRequired && store.userData.loggedIn) {
		next('/');
	} else {
		next();
	}
});

export default router;
