<template>
    <navigation-bar v-if="$route.name !== 'Login'" />
    <router-view :class="{ content: $route.name !== 'Login' }" />
</template>

<script>
import Navigation from './components/navigation/Navigation.vue';

export default {
	name: 'App',
	components: {
		'navigation-bar': Navigation
	}
};
</script>

<style>
@import "./app.css";

.content {
  padding: 0 1rem 2rem;
  max-width: 960px;
  margin: 0 auto;
}
</style>
