<template>
	<nav>
		<div class="menu-wrapper">
			<router-link tag="h1" :to="{ name: 'Home'  }" class="logo">BARRACKS</router-link>
			<router-link :to="{ name: 'Factions' }">Factions</router-link>
		</div>
		<a href="#" @click="logout">Log out</a>
	</nav>
</template>

<script>
import { firebase } from '@firebase/app';
import '@firebase/auth';

export default {
	name: 'Navigation',
	methods: {
		logout() {
			firebase
				.auth()
				.signOut()
				.then(() => {
					this.$router.push({ name: 'Login' });
				});
		}
	}
};
</script>

<style lang="scss" scoped>
nav {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	max-width: 960px;
	margin: auto;
	padding: 1rem 2rem;
	font-size: 1.2rem;

	h1 {
		margin: 0;
	}

	.menu-wrapper {
		color: #8a6c3c;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		.logo {
			font-size: 3rem;
			cursor: pointer;
		}
	}

	a {
		&:not(:first-child) {
			margin-left: 2rem;
			margin-right: 0;
		}

		&:focus,  &:active {
			outline: 0;
			text-decoration: none;
		}
	}
}
</style>
