import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';

import { firebase } from '@firebase/app';
import '@firebase/auth';

require('./firebase.js');

const pinia = createPinia();

let app;

firebase.auth().onAuthStateChanged(() => {
	if(!app) {
		app = createApp(App).use(router).use(pinia).mount('#app');
	}
});