import { firebase } from '@firebase/app';

// Initialize Firebase
var config = {
	apiKey: 'AIzaSyD93N9QhSn5U93ELSUORpIylWG81VQfSvM',
	authDomain: 'barracks-a1949.firebaseapp.com',
	projectId: 'barracks-a1949',
	storageBucket: 'barracks-a1949.appspot.com',
	messagingSenderId: '207784969306',
	appId: '1:207784969306:web:66d7c3762ae8bac7f38463'
};

firebase.initializeApp(config);
